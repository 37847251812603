import { memo, useContext } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'

import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useUpdateEffectAsync from '../../../hooks/useUpdateEffectAsync'
import { LoggedInUserContext, isOpenAccessWeek } from '../../../context/LoggedInUser'
import { ChannelIdInPWAContext } from '../../../context/ChannelIdInPWA'

import InfoDialog from '../../common/InfoDialog'
import LinkButton from '../../common/LinkButton'
import SignInButton from '../../common/SignInButton'
import { IS_EMBED } from '../../../utils/constants'

const StyledInfoDialog = styled(InfoDialog)`

  z-index: 8000 !important;

  @media (max-width: 400px) {
    .MuiDialog-paper {
      margin: 0;
      height: 100dvh;
      max-height: none;
    }
  }

  .MuiDialogContent-root {
    padding-bottom: 25px;
  }

`

const Container = styled.div`
`

const IntroExplanation = styled.div`
  margin: -10px 0 0;
`

const Explanation = styled.div`
  margin: 0 0 10px;
`

const ReadyToSubscribe = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 30px 0 10px;
  text-align: center;
`

const InviteToSubscribe = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px;
`

const MainMenuBanner = styled.div`
  background: black;
  padding: 30px 0;
  text-align: center;
  font-size: 23px;
  line-height: 1.2;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      .OpenAccessWeekDialog-OpenAccessWeekReflection {
        transform: rotatex(180deg) translatey(-50px) scale(2);
      }
      
      .OpenAccessWeekDialog-LearnMore {
        color: white;
      }
    }
  }
`

const OpenAccessWeekText = styled.span`
  font-weight: 300;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
`

const Open = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const Access = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`

const Week = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`

const OpenAccessWeekReflection = styled.div`
  transform: rotatex(180deg) translatey(-50px) scale(1.4);
  filter: blur(3px);
  margin: -30px 0 0;
  transition: 1.25s ease-in-out transform;
`

const LearnMore = styled.div`
  color: rgb(255 255 255/.7);
  font-size: 14px;
  text-decoration: underline;
  font-weight: 300;
  text-shadow: 0 0 35px white;
  transition: .15s ease-in-out color;
`

const Logo = styled.img`
  height: 40px;
  margin: 20px 0 -5px -10px;
`

const LogoContainerForCssb = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LetFriendsKnow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 40px 0 30px;
`

const LetFriendsKnow2 = styled.div`
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.palette.primary.faded};
  padding: 0 4px;
  font-style: italic;
`

const LogoPlusLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Plus = styled.div`
  font-size: 30px;
  margin: 0 0 -20px;
`

const OpenAccessWeekDialog = ({
  mainMenuBanner,
  onClose,
}) => {

  const {
    id,
    hasMyPlanWithoutOpenAccessWeek,
    hasToolsPlanWithoutOpenAccessWeek,
  } = useContext(LoggedInUserContext) || {}
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const [ open, toggleOpen ] = useSimpleToggle(!mainMenuBanner)

  useUpdateEffectAsync(
    () => {
      if(!open) {
        onClose && onClose()
      }
    },
    [ !open ]
  )

  if(!isOpenAccessWeek) return null

  const openAccessWeekText = (
    <OpenAccessWeekText>
      <Open>Open</Open>
      <Access>Access</Access>
      <Week>Week</Week>
    </OpenAccessWeekText>
  )

  const mode = (
    false
    || (channelIdInPWA && `cssb`)
    || (!id && `no-login`)
    || (hasToolsPlanWithoutOpenAccessWeek && `has-tools`)
    || (hasMyPlanWithoutOpenAccessWeek && `has-my`)
    || `no-sub`
  )

  if(IS_EMBED) return null

  return (
    <>

      {!!mainMenuBanner &&
        <MainMenuBanner
          onClick={toggleOpen}
        >
          {openAccessWeekText}
          <OpenAccessWeekReflection className="OpenAccessWeekDialog-OpenAccessWeekReflection">
            {openAccessWeekText}
          </OpenAccessWeekReflection>
          <LearnMore className="OpenAccessWeekDialog-LearnMore">
            {i18n("Learn more")}
          </LearnMore>
        </MainMenuBanner>
      }

      <StyledInfoDialog
        title={<div>{i18nReact("It’s {{event}}!", { event: openAccessWeekText })}</div>}
        open={open}
        onOkay={toggleOpen}
        showCloseIconButton
        explanation={
          <Container>

            {mode === `has-tools` &&
              <>

                <IntroExplanation>
                  {i18n("For this week only, everyone enjoys free access to Biblearc’s premium features.")}
                </IntroExplanation>

                <LogoPlusLogo>
                  <Logo src="/my_biblearc_study_bible_1.svg" />
                  <Plus>+</Plus>
                  <Logo src="/biblearc_tools_1.svg" />
                </LogoPlusLogo>

                <LetFriendsKnow>
                  <LetFriendsKnow2>
                    {i18n("Let all your friends know!")}
                  </LetFriendsKnow2>
                </LetFriendsKnow>

              </>
            }

            {[ `no-sub`, `has-my`, `no-login` ].includes(mode) &&
              <>

                <IntroExplanation>
                  {i18n("For this week only, enjoy free access to Biblearc’s premium features.")}
                </IntroExplanation>

                {mode !== `has-my` &&
                  <>
                    <Logo src="/my_biblearc_study_bible_1.svg" />

                    <Explanation>
                      {i18n("Integrate your church’s sermons and videos from popular ministries, powerfully markup your Bible, and personalize maps.")}
                      {` `}
                      {i18n("Make The Biblearc Study Bible your own.")}
                    </Explanation>

                    {mode !== `no-login` &&
                      <LinkButton
                        onClick={toggleOpen}
                        fullWidth
                        variant="contained"
                        disableElevation
                        to="/your-my-bsb-subscription"
                      >
                        {i18n("Learn More")}
                      </LinkButton>
                    }
                  </>
                }

                <Logo src="/biblearc_tools_1.svg" />

                <Explanation>
                  {i18n("Create Bible study projects with the power of Arcing, Bracketing, Phrasing, Markup, the Outline module, and more.")}
                  {` `}
                  {i18n("“Visual Meditations” using graphical Bible study methods.")}
                </Explanation>

                {mode !== `no-login` &&
                  <LinkButton
                    onClick={toggleOpen}
                    fullWidth
                    variant="contained"
                    disableElevation
                    to="/your-tools-subscription"
                  >
                    {i18n("Learn More")}
                  </LinkButton>
                }

                {mode === `no-login` &&
                  <InviteToSubscribe>

                    <ReadyToSubscribe>
                      {i18n("Sign in or create a free account to try them out!")}
                    </ReadyToSubscribe>

                    <SignInButton />

                  </InviteToSubscribe>
                }

                {mode !== `no-login` &&
                  <InviteToSubscribe>

                    <ReadyToSubscribe>
                      {i18n("Do you like what you’ve discovered?")}
                    </ReadyToSubscribe>

                    <LinkButton
                      onClick={toggleOpen}
                      variant="contained"
                      disableElevation
                      color="primary"
                      to="/subscribe"
                    >
                      {i18n("Subscribe Now")}
                    </LinkButton>

                  </InviteToSubscribe>
                }

              </>
            }

            {mode === `cssb` &&
              <>

                <IntroExplanation>
                  {i18n("In addition to easy access to your church’s sermons, did you know that you can also follow popular ministry channels, unlock more markup tools, and see visual study Bible entries from the Biblearc team with an inexpensive subscription ($2.99 / month) to My Biblearc Study Bible?")}
                  {` `}
                  {i18n("Check it out for free—this week only.")}
                </IntroExplanation>

                <LogoContainerForCssb>
                  <Logo src="/my_biblearc_study_bible_1.svg" />
                </LogoContainerForCssb>

                <InviteToSubscribe>
                  <ReadyToSubscribe>
                    {i18n("Open biblearc.com in your phone’s browser to try it out.")}
                  </ReadyToSubscribe>
                </InviteToSubscribe>

              </>
            }

          </Container>
        }
      />

    </>
  )
}

export default memo(OpenAccessWeekDialog)