import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useSimpleToggle from '../../../hooks/useSimpleToggle'

import RelativeTime from '../../common/RelativeTime'
import Avatar from '../../common/Avatar'

const Container = styled.div`
  width: 648px;
  max-width: calc(100vw - 30px);
`

const Main = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.divider}; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
`

const Extra = styled.div`
  background: ${({ theme }) => theme.palette.grey[100]}88;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`

const StyledIconButton = styled(IconButton)`
  transition: transform .15s ease-in-out;

  ${({ $showMore }) => !$showMore ? `` : `
    transform: rotate(180deg);
  `}
`

const Plan = styled.div`
  width: 60px;
`

const Who = styled.div`
  width: 60px;
  font-weight: 200;
`

const NumDays = styled.div`
  width: 80px;
  font-weight: 200;
`

const NumDaysNum = styled.span`
  font-weight: normal;
`

const Expires = styled.div`
  width: 190px;
  font-weight: 200;
`

const ExpiresDate = styled.span`
  font-weight: normal;
`

const NumUsed = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`

const Num = styled.div`
`

const Used = styled.div`
  font-weight: 200;
`

const CreatedBy = styled.div`
  width: 70px;
  display: flex;
  justify-content: center;
`

const Code = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.grey[200]};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 50px;
  line-height: 1;
  padding: 5px 0;
  width: 80px;
`

const CodeId = styled.div`
  font-size: 14px;
`

const CodeInfo = styled.div`
  display: flex;
  justify-content: center;
  font-size: 10px;
  font-weight: 200;
  user-select: none;
`

const CodeUser = styled.div`
  font-weight: 300;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TooltipContent = styled.div`
  text-align: center;
`

const TooltipUser = styled.div`
  font-weight: 300;
`

const GiveAwayCodeRow = ({
  actionCodes,
}) => {

  const {
    // action,
    id,
    details,
    expiresAt,
    user,
  } = actionCodes[0]

  const {
    plan,
    numDays,
    firstTimeSubscribersOnly,
  } = details

  const [ showMore, toggleShowMore ] = useSimpleToggle()

  // action,
  // details,
  // createdAt,
  // expiresAt,
  // id,
  // usedAt,
  // user,
  // subscription,

  return (
    <Container>

      <Main>

        <StyledIconButton
          onClick={toggleShowMore}
          $showMore={showMore}
          disabled={/^fake:/.test(id)}
        >
          <ExpandMoreIcon />
        </StyledIconButton>

        <Plan>
          {plan}
        </Plan>

        <Who>
          {firstTimeSubscribersOnly
            ? i18n("new-only", "", "admin")
            : i18n("any-user", "", "admin")
          }
        </Who>

        <NumDays>
          {i18nReact("{{days}} day(s)", "", "admin", {
            days: (
              <NumDaysNum>
                {numDays}
              </NumDaysNum>
            ),
          })}
        </NumDays>

        <Expires>
          {i18nReact("Expires: {{date}}", "", "admin", {
            date: (
              <ExpiresDate>
                <RelativeTime date={expiresAt} />
              </ExpiresDate>
            ),
          })}
        </Expires>

        <NumUsed>
          <Num>
            {actionCodes.filter(({ usedAt }) => usedAt).length}
            /
            {actionCodes.length}
          </Num>
          <Used>
            {i18n("used", "", "admin", {
              date: (
                <RelativeTime date={expiresAt} />
              ),
            })}
          </Used>
        </NumUsed>

        <Tooltip
          title={i18n("Created by {{name}}", "", "admin", user)}
        >
          <CreatedBy>
            <Avatar user={user} />
          </CreatedBy>
        </Tooltip>

      </Main>

      {showMore &&
        <Extra>
          {actionCodes.map(({ id, usedAt, subscription }) => (
            <Tooltip
              key={id}
              interactive
              title={!usedAt ? `` : (
                <TooltipContent>
                  <RelativeTime date={usedAt} />
                  <TooltipUser>
                    {((subscription || {}).user || {}).name || `[unknown user]`}
                    {` `}
                    (ID: {((subscription || {}).user || {}).id})
                  </TooltipUser>
                </TooltipContent>
              )}
            >
              <Code>
                <CodeId>
                  {id}
                </CodeId>
                <CodeInfo>
                  {!usedAt && i18n("Not yet used", "", "admin")}
                  {usedAt &&
                    <CodeUser>
                      {((subscription || {}).user || {}).name || `USED`}
                    </CodeUser>
                  }
                </CodeInfo>
              </Code>
            </Tooltip>
          ))}
        </Extra>
      }

    </Container>
  )
}


export default memo(GiveAwayCodeRow)