import { memo, useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { i18n } from 'inline-i18n'

import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Header from "../../common/Header"
import UserTab from "./UserTab"
import BibleDataTab from './BibleDataTab'
import StatsTab from './StatsTab'
import GiveAwayCodesTab from './GiveAwayCodesTab'
import ReportsTab from './ReportsTab'
import AlertsTab from './AlertsTab'
import StudyBibleTab from './StudyBibleTab'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  padding: 30px 15px;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  background: white;
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Admin = ({ ...props }) => {

  const user = useContext(LoggedInUserContext)

  const [ tabIndex, setTabIndex ] = useState(0)

  const onChange = useCallback((event, newIndex) => setTabIndex(newIndex), [])

  const tabs = []

  if([ 'ADMIN' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Users", "", "admin"),
      component: <UserTab />
    })
  }

  if([ 'ADMIN', 'EDITOR', 'CONTRIBUTOR' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Study Bible", "", "admin"),
      component: <StudyBibleTab />
    })
  }

  if([ 'ADMIN', 'EDITOR', 'CONTRIBUTOR', 'REPORTING', 'MARKETING', 'TESTER' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Give-Away Codes", "", "admin"),
      component: <GiveAwayCodesTab />
    })
  }

  if([ 'ADMIN', 'EDITOR', 'CONTRIBUTOR', 'REPORTING', 'MARKETING' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Stats", "", "admin"),
      component: <StatsTab />
    })
  }

  if([ 'ADMIN' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Bible Data", "", "admin"),
      component: <BibleDataTab />
    })
  }

  if([ 'ADMIN', 'MARKETING' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Alerts", "", "admin"),
      component: <AlertsTab />
    })
  }

  if([ 'ADMIN', 'REPORTING' ].includes(user.adminLevel)) {
    tabs.push({
      label: i18n("Reports", "", "admin"),
      component: <ReportsTab />
    })
  }

  if(tabs.length === 0) return null

  return (
    <>

      <Header {...props} />

      <TabsContainer>
        <Tabs
          value={tabIndex}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map(({ label }, idx) => (
            <Tab
              key={idx}
              label={label}
            />
          ))}
        </Tabs>
      </TabsContainer>

      <Container>
        {tabs[tabIndex % tabs.length].component}
      </Container>

    </>
  )
}


export default memo(Admin)