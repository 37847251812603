import { useMemo } from 'react'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { i18n } from 'inline-i18n'

import useEqualObjsMemo from './useEqualObjsMemo'
import { cloneObj } from '../utils/misc'
import usePassageRef from './usePassageRef'

const usePassageRefs = ({
  locSets,
  ...otherProps
 }) => {

  // put in order
  const sortedMemoedLocSets = useEqualObjsMemo(() => cloneObj(locSets || []).sort((a,b) => `${a[0]}-${a[1] || a[0]}` < `${b[0]}-${b[1] || b[0]}` ? -1 : 1), [ locSets ])

  const doWithoutBookName = idx => (
    idx !== 0
    && getRefFromLoc(sortedMemoedLocSets[idx-1][0]).bookId === getRefFromLoc(sortedMemoedLocSets[idx][0]).bookId
  )

  const getParams = idx => (
    sortedMemoedLocSets[idx]
      ? {
        ...otherProps,
        fromLoc: sortedMemoedLocSets[idx][0],
        toLoc: sortedMemoedLocSets[idx][1],
        withoutBookName: doWithoutBookName(idx),
        noWithoutBookNameExceptions: true,
      }
      : {
        fromLoc: `01001001`,
        toLoc: `01001001`,
      }
  )

  // get the individual passageRefs
  let i = 0
  const passageRefs = [
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
    usePassageRef(getParams(i++)),
  ]

  // combine
  const passageRefsStr = useMemo(
    () => {

      let str = ``

      sortedMemoedLocSets.forEach((locSet, idx) => {
        if(!passageRefs[idx]) return
        if(idx === 0) {
          // nothing
        } else if(
          sortedMemoedLocSets[idx-1][0].slice(0,5) === (sortedMemoedLocSets[idx-1][1] || sortedMemoedLocSets[idx-1][0]).slice(0,5)
          && locSet[0].slice(0,5) === (locSet[1] || locSet[0]).slice(0,5)
          && sortedMemoedLocSets[idx-1][0].slice(0,5) === locSet[0].slice(0,5)
        ) {
          str += i18n(", ", "verse combiner")
        } else {
          str += i18n("; ", "passage combiner")
        }
        str += passageRefs[idx]
      })

      if(sortedMemoedLocSets.length > passageRefs.length) {
        str += i18n("...", "ellipsis")
      }

      return str

    },
    [ sortedMemoedLocSets, ...passageRefs ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  return passageRefsStr

}

export default usePassageRefs