import { memo, useState } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

import useEffectAsync from '../../hooks/useEffectAsync'
import useInstanceValuesCallback from '../../hooks/useInstanceValuesCallback'

const StyledTextField = styled(TextField)`
  margin-bottom: 30px;
  width: 209px;

  input {
    font-size: 34px;
    font-weight: bold;
    font-family: Courier, monospace;
    letter-spacing: 9px;
    padding: 17px 0 17px 21px;
  }
`

const CodeTextField = ({
  setCompletedCode,
  initialValue,
  numChars=6,
  forbiddenCharsRegex=/[^A-Z]/,
  ...otherProps
}) => {

  const [ code, setCode ] = useState(initialValue || ``)

  const onChange = useInstanceValuesCallback(
    ({ target }) => {
      setCode(
        target.value
          .toUpperCase()
          .replace(forbiddenCharsRegex, ``)
          .slice(0, numChars)
      )
    }
  )

  useEffectAsync(
    () => {
      setCompletedCode(
        code.length === numChars
          ? code
          : undefined
      )
    },
    [ code ],
  )

  return (
    <StyledTextField
      value={code}
      onChange={onChange}
      variant="outlined"
      {...otherProps}
    />
  )
}


export default memo(CodeTextField)