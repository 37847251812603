import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import { getPiecesFromUSFM, getRefsFromUsfmRefStr, getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"
import { getLocFromRef } from '@bibletags/bibletags-versification'

import useVersionInfo from '../../hooks/useVersionInfo'
import useVersesPieces from '../../hooks/useVersesPieces'
import { getPassageTextSize, getPassageLineSpacing } from '../../utils/misc'
import { POPPER_LINE_SPACING_SIZES, POPPER_TEXT_SIZES } from '../../utils/constants'

import PassagePopperHeading from './PassagePopperHeading'
import PassagePopperContainer from './PassagePopperContainer'
import TextContent from '../common/TextContent'

const StyledDivider = styled(Divider)`
  margin: 15px -16px;
`

const PassageRefTextContainer = styled.div`
  position: relative;
  min-height: 40px;

  ${({ $font, $textSize, $lineSpacing, $isRTL }) => `
    font-family: ${$font || 'inherit'};
    font-size: ${$textSize}px;
    line-height: ${$lineSpacing};
    direction: ${$isRTL ? "rtl" : "ltr"};
  `}

`

const FootnotePopperContent = ({
  versionId,
  content,
  selectedIdx,
  isCf,
  goSetPopperInfo,
  closeAndClearSearch,
  updateProps,
  hasHeader,
}) => {

  const { version } = useVersionInfo(versionId)
  const { wordDividerRegex } = version || {}

  const { pieces, selectedRefs, numXtTags } = useMemo(
    () => {
      const pieces = getPiecesFromUSFM({
        usfm: content.replace(/^. /, ``),
        inlineMarkersOnly: true,  // this should become false to allow for \fp
        wordDividerRegex,
      })

      let selectedRefs
      const xtTags = pieces.filter(({ tag }) => tag === 'xt')

      xtTags.forEach((piece, idx) => {
        if(!piece.attrib) {
          piece.tag = `xt-noref`
        } else if(idx === selectedIdx) {
          piece.otherAttrs = {
            className: 'selected',
          }
          selectedRefs = getRefsFromUsfmRefStr(piece.attrib.slice(1))
          // only allow it to span 2 chapters at most
          if(
            selectedRefs.length === 2
            && selectedRefs[1].chapter > selectedRefs[0].chapter + 1
          ) {
            selectedRefs[1].chapter = selectedRefs[0].chapter + 1
            selectedRefs[1].verse = 999
          }
        } else {
          piece.otherAttrs = {
            onClick: () => {
              updateProps({
                selectedIdx: idx,
              })
            },
          }
        }
      })

      return {
        pieces,
        selectedRefs,
        numXtTags: xtTags.length,
      }
    },
    [ content, wordDividerRegex, selectedIdx, updateProps ],
  )

  const [ selectedPassageRefPieces ] = useVersesPieces({
    fromLoc: selectedRefs && getLocFromRef(selectedRefs[0]),
    toLoc: (selectedRefs || []).length > 1 && getLocFromRef(selectedRefs[1]),
    versionId,
    skip: !selectedRefs,
  })

  const { languageId: preadjustedLanguageId } = version || {}
  const { bookId } = (selectedRefs || [])[0] || {}
  const languageId = getTextLanguageId({ languageId: preadjustedLanguageId, bookId })
  const isRTL = isRTLText({ languageId, bookId })

  const textSize = getPassageTextSize({
    textSizes: POPPER_TEXT_SIZES,
    languageId,
  })

  const lineSpacing = getPassageLineSpacing({
    lineSpacingSizes: POPPER_LINE_SPACING_SIZES,
    languageId,
  })

  return (
    <PassagePopperContainer $hasHeader={hasHeader} >

      {!!isCf &&
        <PassagePopperHeading>
          {
            numXtTags === 1
              ? i18n("Cross-reference")
              : i18n("Cross-references")
          }
        </PassagePopperHeading>
      }

      <div>
        <TextContent
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={selectedRefs && selectedRefs[0].chapter}
          loadingSize={20}
          goSetPopperInfo={goSetPopperInfo}
        />
      </div>

      {!!selectedRefs &&
        <>

          <StyledDivider />

          <PassageRefTextContainer
            $isRTL={isRTL}
            $textSize={textSize}
            $lineSpacing={lineSpacing}
          >
            <TextContent
              pieces={selectedPassageRefPieces}
              versionId={versionId}
              bookId={bookId}
              startChapter={selectedRefs[0].chapter}
              loadingSize={20}
              goSetPopperInfo={goSetPopperInfo}
              showExpand={(selectedPassageRefPieces || {}).length > 0}
              closeAndClearSearch={closeAndClearSearch}
            />
          </PassageRefTextContainer>

        </>
      }

    </PassagePopperContainer>
  )
}

export default memo(FootnotePopperContent)