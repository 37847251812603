const fields = `
  id
  action
  details
  expiresAt
  usedAt
  createdAt
  user {
    id
    name
    image
  }
  subscription {
    id
    user {
      id
      name
      image
    }
  }
`

export default fields