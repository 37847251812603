import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLiveQuery } from "dexie-react-hooks"
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'

import { db } from '../utils/database'
import { IS_EMBED } from '../utils/constants'
import { callOnFocus, getLocalStorage, logoutCallback } from '../utils/misc'
import useDataQuery from '../hooks/useDataQuery'
import useEffectAsync from '../hooks/useEffectAsync'
import useMutationContext from '../hooks/useMutationContext'
import userQuery from '../graphql/queries/user'
import { ChannelIdInPWAContext } from './ChannelIdInPWA'

import Loading from '../components/common/Loading'

import accountSettingsQuery from '../graphql/queries/accountSettings'

const OPEN_ACCESS_WEEK_RANGE = [ new Date(`2025-02-02`), new Date(`2025-02-09`) ]

export const isOpenAccessWeek = (
  (OPEN_ACCESS_WEEK_RANGE || []).length === 2
  && OPEN_ACCESS_WEEK_RANGE[0] < Date.now()
  && OPEN_ACCESS_WEEK_RANGE[1] > Date.now()
)

export const LoggedInUserContext = React.createContext()

export const LoggedInUserContextProvider = ({
  children,
}) => {

  const [ navigatingToLogin, setNavigatingToLogin ] = useState(false)

  const hasAccessToken = !IS_EMBED && !!window.sessionSyncAuth.getAccessToken()
  const context = useMutationContext()
  const client = useApolloClient()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const defaultValue = useMemo(
    () => (
      hasAccessToken  // presence of token indicates an apparent logged in state
        ? {
          id: getLocalStorage('user.id'),
          name: getLocalStorage('user.name'),
          image: getLocalStorage('user.image'),
        }
        : null
    ),
    [],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const users = useLiveQuery(
    () => (
      db.users
        .toArray()
    ),
    [],
    [ defaultValue ],
  )

  // only send a new object when there was a real update
  let user = useMemo(() => users[0], [ JSON.stringify(users[0]) ])  // eslint-disable-line react-hooks/exhaustive-deps

  if(getLocalStorage('inLoginProcess')) {
    user = defaultValue
  }

  useEffectAsync(
    () => {
      if(!user) {
        Sentry.setUser(null)
      } else {
        Sentry.setUser({
          id: user.id,
          username: user.name,
          email: user.email,
        })
      }
    },
    [ user ],
  )

  useEffectAsync(
    () => {
      const searchParams = new URLSearchParams(window.location.search)
      if(searchParams.get(`autologin`) !== null) {
        searchParams.delete(`autologin`)
        let newSearch = searchParams.toString()
        if(newSearch.length > 0) {
          newSearch = `?${newSearch}`
        }
        window.history.replaceState(
          window.history.state,
          '',
          `${window.location.pathname}${newSearch}${window.location.hash}`,
        )
        if(!hasAccessToken) {
          window.sessionSyncAuth.logIn()
          setNavigatingToLogin(true)
        }
      }
    },
    [],
  )

  if(!hasAccessToken && user) {
    // dexie indicates a login, but the token is missing
    logoutCallback()
  }

  useDataQuery({
    userQuery,  // dexie user gets updated in ApolloSetup when this returns from the server
    refetchOnFocus: true,
    skip: !hasAccessToken,
  })

  useEffect(
    () => {
      if(!hasAccessToken) return

      // NOTE: cannot call with useDataQuery since it will constantly call it (given that updatedSince is added)
      const refreshAccountSettings = () => {
        client.query({
          query: accountSettingsQuery,
          fetchPolicy: `network-only`,
          context,
        })
      }
      refreshAccountSettings()
      return callOnFocus(refreshAccountSettings)
    },
    [ hasAccessToken, client, context ],
  )

  if((user || {}).email && !channelIdInPWA) {
    const now = Date.now()

    user.hasMyPlan = (user.activeSubscriptions || []).some(({ plan, currentPeriodEndsAt, status }) => (
      [ 'MY', 'TOOLS', 'EQUIP' ].includes(plan)
      && currentPeriodEndsAt > now
      && [ 'ACTIVE', 'TRIALING' ].includes(status)
    ))
    // user.hasMyPlan = false

    user.hasToolsPlan = (user.activeSubscriptions || []).some(({ plan, currentPeriodEndsAt, status }) => (
      [ 'TOOLS', 'EQUIP' ].includes(plan)
      && currentPeriodEndsAt > now
      && [ 'ACTIVE', 'TRIALING' ].includes(status)
    ))

    user.hasEquipPlan = (user.activeSubscriptions || []).some(({ plan, currentPeriodEndsAt, status }) => (
      plan === 'EQUIP'
      && currentPeriodEndsAt > now
      && [ 'ACTIVE', 'TRIALING' ].includes(status)
    ))

    if(isOpenAccessWeek) {
      user.hasMyPlanWithoutOpenAccessWeek = user.hasMyPlan
      user.hasToolsPlanWithoutOpenAccessWeek = user.hasToolsPlan
      user.hasMyPlan = user.hasToolsPlan = true
    }
  }

  const showLoading = (user || {}).id === "0" || navigatingToLogin

  return (
    <LoggedInUserContext.Provider value={user}>
      {showLoading && <Loading />}
      {!showLoading && children}
    </LoggedInUserContext.Provider>
  )
}